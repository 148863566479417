import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Icon } from '../components/icon';
import { LinkCard } from '../components/LinkCard';
import PageWithHeader from '../components/pageWithHeader';
import style from './insights.module.scss';

export type InsightsReworkProps = {
    data: GatsbyTypes.InsightsPageQuery
}

export type OrderToggleProps = {
    orderState: 'ascending' | 'descending',
    setOrderState: React.Dispatch<React.SetStateAction<'ascending' | 'descending'>>
}

const OrderToggle = ({orderState, setOrderState}: OrderToggleProps) => {
    const toggle = () => setOrderState(orderState === 'ascending' ? 'descending' : 'ascending');
    return (<button aria-label="Sort order" className={style.toggleButton} title={orderState} onClick={toggle}>{orderState === 'ascending' ? <Icon>north</Icon> : <Icon>south</Icon>}</button>);
}

export type TopicSelectProps = {
    filterState: string | undefined,
    setFilterState: React.Dispatch<React.SetStateAction<string | undefined>>,
    topics: Pick<GatsbyTypes.ContentfulTag, "name" | "id">[]
}

const TagSelect = ({filterState, setFilterState, topics}: TopicSelectProps) => {

    return (<div className={style.topicSelect}><select aria-label="Filter by tag" value={filterState} onChange={(e) => setFilterState(e.currentTarget.value)}>
        <option value="All Topics">All Topics</option>
        {(topics || []).map(t => (<option key={t.name} value={t.name}>{t.name}</option>))}
    </select></div>);
}

const InsightsRework = ({data}: InsightsReworkProps) => {
    const articles = data?.allContentfulArticle?.edges.map((a, i) => ({...a.node, createdAt: a.node?.createdAt ? new Date(a.node.createdAt) : undefined}));

    const [filterState, setFilterState ] = useState<string | undefined>('All Topics');
    const [orderState, setOrderState ] = useState<'ascending' | 'descending'>('descending');
    const [articlesState, setArticlesState] = useState<typeof articles>([]);

    useEffect(() => {
        let filtered = articles
            .sort((a,b) => +(a.createdAt && b.createdAt && (orderState === 'ascending' ? a.createdAt > b.createdAt : b.createdAt > a.createdAt) || -1));
        if(filterState !== 'All Topics') {
            filtered = filtered.filter(a => a.tags?.some(t => t?.name === filterState));
        }
        setArticlesState(filtered);
    }, [filterState, orderState]);

    return (
        <PageWithHeader
            title="Insights & Trends"
            description="Insights & Trends"
            headerImage={data?.contentfulInsightPage?.spotlightImage?.fluid?.src}
        >
            <article className={style.article}>
                <div className={style.insightsHeader}>
                    <h2>{filterState || 'Most Recent'}<OrderToggle {...{orderState, setOrderState}}/></h2>
                    <TagSelect {...{filterState, setFilterState, topics: data?.allContentfulTag?.edges.map(t => t.node)}}/>
                </div>
                <div className={style.insights}>
                    {articlesState.map(article => (
                        <div className={style.card} key={article.slug}>
                            <LinkCard
                                title={article.title || ""}
                                image={article.articleImage}
                                link={`/article/${article.slug}`}
                            />
                        </div>
                    ))}
                </div>
            </article>
        </PageWithHeader>
    );
}

export default InsightsRework;

export const InsightsPageData = graphql`
    query InsightsPage {
        contentfulInsightPage {
            name
            title
            spotlightImage {
                fluid(quality: 100) {
                    src
                }
            }
        },
        allContentfulTag {
            edges {
                node {
                    name
                    id
                }
            }
        }
        allContentfulArticle(filter: {category: {category: {in: ["Insight", "Trend"]}}}) {
            edges {
                node {
                    author
                    description {
                        description
                    }
                    slug
                    createdAt
                    tags {
                        name
                        id
                    }
                    title
                    articleImage {
                        localFile {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;